import React, { useState } from 'react';

import SubHeading from '../SubHeading/SubHeading';
import './Newsletter.css';

const Newsletter = () => {

  const [ message, setMessage ] = useState('')
  
  const handleSendMessage = ()=>{
    const encodedMessage = encodeURIComponent(message.target.value);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=+818014108889&text=${encodedMessage}`;
    return window.open(whatsappUrl);
  }
  return (
    <div className="app__newsletter">
    <div className="app__newsletter-heading">
      <SubHeading title="WhatsApp Us for Reservation" />
      <h1 className="headtext__cormorant">Send Us A Message on WhatsApp</h1>
      <p className="p__opensans">Book your Reservation</p>
    </div>
    <div className="app__newsletter-input flex__center">
      <input type="email" placeholder="Name & Reservation time" onChange={(text)=>setMessage(text)} />
      <button type="button" className="custom__button" onClick={handleSendMessage}>Send</button>
    </div>
  </div>
  )
}

export default Newsletter;