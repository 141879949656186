import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Header.css";
import Particles from "./Particles";

const MusicNotes = () =>{
  return (
    <div className="muzieknootjes">
        <div className="noot-1">&#9835; &#9833;</div>
        <div className="noot-2">&#9833;</div>
        <div className="noot-3">&#9839; &#9834;</div>
        <div className="noot-4">&#9834;</div>     
        <div className="noot-4">
          <small style={{ fontSize: 15}} >Karaoke</small>  
        </div> 
        <div className="noot-1">
          <small style={{ fontSize: 15}} >Karaoke</small>  
        </div> 
        <div className="noot-2">
          <small style={{ fontSize: 15}} >Karaoke</small>  
        </div> 
        <div className="noot-3">
          <small style={{ fontSize: 15}} >Karaoke</small>  
        </div>  
    </div>
  )
}

const Header = () => (
  <>
  <h3 style={{ background: "purple", color: "#fff", textAlign: "center" }}>You can sing at our Karaoke Bar. カラオケバーで歌うことができます。</h3>
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Yokohama's New Flavor" />
      <h1 className="app__header-h1">Finest Indonesian Cusine</h1>
      <p className="p__opensans" style={{ margin: "2rem 0" }}>
      Welcome! Discover a delightful blend of Indonesian and Japanese flavors in our cozy space. The ambient lighting sets a relaxed tone for your enjoyment. Indulge in delectable dishes from Indonesia, savor quality drinks, and immerse yourself in the joy of karaoke. Unwind, relish the food, sing to your heart's content – make the most of your time here. Welcome and let's create memorable moments together! 🎤🍜🎉
      </p>
      <button type="button" className="custom__button">
        <a href="#menu">Explore Menu</a>
      </button>
    </div>

    <div className="app__wrapper_img">
        {[1,2].map((n)=> <MusicNotes key={n} />)}
        <Particles />
        <Particles />
        <div className="circle"></div>
        <img src={images.place} alt="header_img" />

    </div>
  </div>
  </>
);

export default Header;
