import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">Kanagawa-ken, Yokohama-shi, hodogaya-ku, tennocho</p>
        <p className="p__opensans">080-1410-8889</p>
      </div>

      <div className="app__footer-links_logo">
        {/* <img src={images.gericht} alt="footer_logo" /> */}
        <h1 style={{ color: 'white' }}>NYI INTAN CAFE</h1>
        <p className="p__opensans">&quot;Best Authentic Indonesia restaurant in Yokohama&quot;</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <a href='https://www.facebook.com/nyiintancafe' target="_blank" rel="noopener noreferrer"><FiFacebook /></a>
          <a href='https://www.instagram.com/nyiintancafe/' target="_blank" rel="noopener noreferrer"><FiInstagram /></a> 
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Tuesday-Sunday:</p>
        <p className="p__opensans">11:00 AM - 22:00 PM</p>
        <p className="p__opensans">We request you to make prior booking before the day of arrival.</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2024 NYI INTAN. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;