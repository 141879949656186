import images from './images';

const wines = [
  {
    title: 'Nasi Bebek Betutu',
    price: '¥1800',
    jpnTitle: 'ベトゥトゥダックライス'
    // tags: 'AU | Bottle',
  },
  {
    title: 'Nasi Ayam Cremez',
    price: '¥1300',
    jpnTitle: 'クリーミーチキンライス',
    // tags: 'AU | Bottle',
  },
  {
    title: 'Nasi Gurame Goreng',
    price: '¥1700',
    jpnTitle: 'フライドグラミライス'
    // tags: 'FR | 750 ml',
  },
  {
    title: 'Lontong Sayur',
    price: '¥1300',
    jpnTitle: 'ロントン野菜'
    // tags: 'CA | 750 ml',
  },
  {
    title: 'NasGor Nyai',
    price: '¥1300',
    jpnTitle: 'ナスゴルニャイ'
    // tags: 'IE | 750 ml',
  },
  {
    title: 'Nasi Bebek Goreng',
    price: '¥1700',
    jpnTitle: 'ダックライス'
    // tags: 'AU | Bottle',
  },
  {
    title: 'NasGor Cabe Ijo',
    price: '¥1400',
    // tags: 'AU | Bottle',
  },
  {
    title: 'Ayam Bakar Arang',
    price: '¥1300',
    // tags: 'FR | 750 ml',
  },
  {
    title: 'Sate Madura',
    price: '¥1300',
    // tags: 'CA | 750 ml',
  },
  {
    title: 'Mie Goreng',
    price: '¥1400',
    // tags: 'IE | 750 ml',
  },
  {
    title: 'Bakso Nyai',
    price: '¥1300',
    // tags: 'CA | 750 ml',
  },
  {
    title: 'Nasi Kuning Nyai',
    price: '¥1500',
    // tags: 'IE | 750 ml',
  },
  
  {
    title: '鳥焼肉',
    price: '¥650',
    // tags: 'FR | 750 ml',
  },
  {
    title: 'フライドフレイス',
    price: '¥400',
    // tags: 'CA | 750 ml',
  },
  {
    title: 'フライド玉葱',
    price: '¥500',
    // tags: 'IE | 750 ml',
  },
  {
    title: '春巻き',
    price: '¥650',
    // tags: 'CA | 750 ml',
  },
  {
    title: 'フライドシレン',
    price: '¥650',
    // tags: 'IE | 750 ml',
  },
  {
    title: 'エンペ エンペ',
    price: '¥850',
    // tags: 'IE | 750 ml',
  },
  {
    title: 'パステル',
    price: '¥750',
    // tags: 'IE | 750 ml',
  },
];

const cocktails = [
  {
    title: 'Nama Biru',
    price: '¥650',
    // tags: 'Aperol | Villa Marchesi prosecco | soda | 30 ml',
  },
  {
    title: "High Ball",
    price: '¥650',
    // tags: 'Dark rum | Ginger beer | Slice of lime',
  },
  {
    title: 'Bintang Biru',
    price: '¥650',
    // tags: 'Rum | Citrus juice | Sugar',
  },
  {
    title: 'Teh Botol',
    price: '¥300',
    // tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Corona Beer',
    price: '¥700',
    // tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
  {
    title: 'Cocacola',
    price: '¥300',
    // tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
  {
    title: 'Teh Kotak',
    price: '¥300',
    // tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Jus Jeruk',
    price: '¥300',
    // tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
  {
    title: 'Coco Milk',
    price: '¥300',
    // tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Jus Apple',
    price: '¥300',
    // tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
  {
    title: 'Teh Tarik',
    price: '¥300',
    // tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
  {
    title: 'Good Day',
    price: '¥300',
    // tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Nutrisari',
    price: '¥300',
    // tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
  {
    title: 'Jahe Wangi',
    price: '¥300',
    // tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
];

const awards = [
  {
    imgUrl: images.award01,
    title: 'Authentic Indonesian Taste',
    subtitle: 'Authentic Indonesian food by Indonesia chefs',
  },
  {
    imgUrl: images.award01,
    title: 'Music and Karaoke Bar',
    subtitle: 'Play your favourite songs on our Karaoke',
  },
  {
    imgUrl: images.award01,
    title: 'NYI Intan Halal Shop',
    subtitle: 'Click here to buy Groceries from our Halal Shop'
  },
  {
    imgUrl: images.award01,
    title: 'NYI Intan Skincare Shop',
    subtitle: 'Click here to buy our Skincare Products'
  },
];

export default { wines, cocktails, awards };
