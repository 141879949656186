import React, { useEffect } from 'react';

const Particles = () => {
  useEffect(() => {
    const particles = document.querySelector('.particles');
    if (!particles) return;

    const emitter = {
      speed: 20, // milliseconds
      div: (parent) => {
        const div = document.createElement('div');
        parent.appendChild(div);
        return div;
      },
      rotation: 1,
      currentRotation: 0,
      particle: () => {
        const particle = emitter.div(particles);
        const div = emitter.div(particle);
        particle.className = 'particle';
        emitter.currentRotation += emitter.rotation;
        particle.style.transform = `translateX(-100%) translateY(-50%) scale(${Math.random()}) rotateZ(${Math.random() * 360}deg)`;
        setTimeout(emitter.particle, emitter.speed);
        setTimeout(() => {
          particles.removeChild(particle);
        }, 1000);
      },
    };

    emitter.particle();
  }, []);

  return (
    <>
    <div className="holder">
      <div className="particles"></div>
    </div>
    </>
  );
};

export default Particles;
