import React from "react";

import { SubHeading } from "../../components";
import { images, data } from "../../constants";
import "./Laurels.css";
import { TikTokEmbed } from "react-social-media-embed";

const AwardCard = ({ award: { imgUrl, title, subtitle } }) => {

  const handleDivClick = () => {
    if(title == "NYI Intan Halal Shop" ){
      window.open('https://nyiintan.jp', '_blank');
    } else if(title == "NYI Intan Skincare Shop"){
      window.open('https://nyiintan.jp/skincare', '_blank');
    } else return
  };
  return (
    <div className="app__laurels_awards-card" onClick={handleDivClick}>
      <img src={imgUrl} alt="awards" />
      <div className="app__laurels_awards-card_content">
        <p className="p__cormorant" style={{ color: "#DCCA87" }}>
          {title}
        </p>
        <p className="p__opensans">{subtitle}</p>
      </div>
    </div>
  );
}

const Laurels = () => (
  <div className="app__bg app__wrapper section__padding" id="awards">
    <div className="app__wrapper_info">
      <SubHeading title="Our Speciality" />
      <h1 className="headtext__cormorant">Services & Specialities</h1>
      <div className="app__laurels_awards-row2">
      <div className="app__laurels_awards">
        {data.awards.map((award) => (
          <AwardCard award={award} key={award.title} />
        ))}
      </div>
      </div>
    </div>
    <div className="app__wrapper_img">
      {/* <img src={images.laurels} alt="laurels" /> */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
  <TikTokEmbed url="https://www.tiktok.com/@nyiintanofficial/video/7338222208566709505" width={325} />
</div>
    </div>
  </div>
);

export default Laurels;
