import React from "react";

import { images } from "../../constants";
import { SubHeading } from "../../components";
import "./Chef.css";

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img_other app__wrapper_img-reverse noscale_wrapper">
      <img className="noscale_wrapper" src={images.owner} alt="chef" />
    </div>

    <div className="app__wrapper_info">
      <SubHeading title="Chef's Word" />
      <h1 className="headtext__cormorant"> What We Belive In</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quots" />
          <p className="p__opensans">
            “Discover the Delicious Blend of Indonesian and Japanese Flavors, Bringing Everyone Closer Together!”
          </p>
        </div>
        <div className="app__chef-sign">
          <p>Nyi Intan</p>
          <p className="p__opensans">Chef & Founder</p>
          <img src={images.sign} alt="chef sign" />
        </div>
      </div>
    </div>
  </div>
);

export default Chef;
