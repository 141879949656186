import React , {useState} from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineClose, MdOutlineRestaurantMenu } from "react-icons/md";

import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {

  const[toggleMenu, setToggleMenu] = useState(false);

  const hideHamMenu = () =>{
    setToggleMenu(!toggleMenu)
  }

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        {/* <img src={images.gericht} alt="app logo" /> */}
        <h2 style={{ color: "white" }}>NYI INTAN CAFE</h2>
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="#home">Home</a>
        </li>
        <li className="p__opensans">
          <a href="#menu">Menu</a>
        </li>
        <li className="p__opensans">
          <a href="#awards">Services</a>
        </li>
        <li className="p__opensans">
          <a href="#contact">Contact</a>
        </li>
      </ul>
      {/* <div className="app__navbar-login">
        <a href="#login" className="p__opensans">
          Log In / Register
        </a>
        <div />
        <a href="https://www.hotpepper.jp/strJ003309002/" target="_blank" className="p__opensans">
          Book Table
        </a>
      </div> */}
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
        <div className="app__navbar-smallscreen_overlay  flex__center slide-bottom">
          <MdOutlineRestaurantMenu
            fontSize={27}
            className="overlay__close"
            onClick={() => setToggleMenu(false)}
          />
       
        <ul className="app__navbar-smallscreen-links">
          <li className="p__opensans">
            <a href="#home" onClick={hideHamMenu}>Home</a>
          </li>
          <li className="p__opensans">
            <a href="#menu" onClick={hideHamMenu}>Menu</a>
          </li>
          <li className="p__opensans">
            <a href="#awards" onClick={hideHamMenu}>Services</a>
          </li>
          <li className="p__opensans">
            <a href="#contact" onClick={hideHamMenu}>Contact</a>
          </li>
        </ul>
      </div>
        )}
      </div>

    </nav>
  );
};

export default Navbar;
